import ApiService from './ApiService'

export async function apiSignIn (data) {
    return ApiService.fetchData({
        url: '/auth/sign-in',
        method: 'post',
        data
    })
}

export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: '/auth/sign-up',
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: '/auth/sign-out',
        method: 'delete',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: '/auth/forgot-password',
        method: 'post',
        data
    })
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/auth/reset-password',
        method: 'post',
        data
    })
}

export async function emailVerification (token) {
    return ApiService.fetchData({
        url: `/auth/email-verification/${token}`,
        method: 'get'
    })
}
